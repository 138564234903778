import React, { useContext } from "react"
import { Link } from "gatsby"
import Template from "../../components/template"
import "../../styles/blog/blogpost.css"
import { WhoAreWeAfraidOfContent } from "../../content/blog/who-are-we-afraid-of"
import { SettingsContext } from "../../contexts/settings"

const WhoAreWeAfraidOfPage = () => {
    const [settings, ] = useContext(SettingsContext)
    const content = WhoAreWeAfraidOfContent[settings.language]
    return (
        <Template currentPage="blog">
            <section>
            <div>
                <h1>
                    <Link to="/blog#who-are-we-afraid-of" class="back-arrow">{content.arrow}</Link>
                    {content.headline}
                </h1>
                <p>{content.date}</p>
                <p>
                    {content.firstFirst}
                    <Link className="link" to="/blog/change-the-narrative">{content.firstLink}</Link>
                    {content.firstSecond}
                </p>
                <p>
                    {content.second}
                </p>
                <p>
                    {content.third}
                    <a className="link" href="https://www.turia.at/titel/spivak_s.php" target="_blank" rel="noopener noreferrer">{content.thirdLink}</a>
                </p>
                <p>
                    {content.fourthFirst}
                    <a className="link" href="https://feministkilljoys.com/2014/08/04/making-strangers/" target="_blank" rel="noopener noreferrer">{content.fourthLink}</a>
                    {content.fourthSecond}
                </p>
                <p>
                    {content.fifth}
                </p>
                < p  className="end-of-site">
                    {content.sixth}
                </p>
            </div>    
            </section>
        </Template >
    )
}

export default WhoAreWeAfraidOfPage