const WhoAreWeAfraidOfContent = {
    german: {
        date: "14. Februar 2021",
        arrow: "BLOG << ",
        headline: " Vor wem haben wir Angst? „Der Fremde“ als rassistische und klassistische Projektion",
        firstFirst: "Dieser Blog-Artikel ist eine Weiterführung ",
        firstLink: "des Berichts zum Workshop „Documenting Nocturnal Flâneuserie“",
        firstSecond: ". Er vertieft die Diskussionen darüber, wie die Gefühle der Angst vor bestimmten Körpern durch gesellschatliche Narrative geprägt ist.",
        second: "Der schwarze männliche Körper wird als Fremder an einem Ort, der vom weißen Blick als weiß imaginiert wird, in einen Körper verwandelt, der als störend, als „merkwürdig“, als potentiell gefährlich gelesen wird. Diese Horror-Phantasie des gefährlichen „Anderen“ stellt weiße Mittelklassefrauen als Opfer dar, die des Schutzes durch die prototypischen weißen männlichen Helden würdig sind, die für „ihre Frauen“ kämpfen. Weiße Frauen werden so zur Verkörperung des reinen Weißseins im Gegensatz zum imaginierten „Anderen“.",
        third: "Es ist wichtig zu erkennen, dass dies an eine gewalttätige Kolonialgeschichte anknüpft, in der der Imperialismus durch das gerechtfertigt wurde und wird, was Sara Ferris als Femonationalismus bezeichnet: die Aneignung feministischer Ideen und der Ruf nach Sicherheit für Frauen mit dem Ziel, Nationalismus, Rassismus, Kolonisation und Patriarchat zu rechtfertigen. Es sind „weiße Männer, die braune Frauen vor braunen Männern retten“, die rassistischen Kolonialismus und Imperialismus moralisch rechtfertigen sollen (Gayatri Spivak in „Can the Subaltern speak“; ",
        thirdLink: "hier klicken, um zur deutschen Ausgabe zu gelangen)",
        fourthFirst:"Diese Geschichte ist nicht ohne Folgen geblieben: ihre Techniken und Ideologien zirkulieren auch heute noch, um unsere Körper und ihre Erscheinung im öffentlichen Raum sozial zu verwalten: „Es kann nichts Gefährlicheres für einen Körper geben als die soziale Übereinkunft, dass dieser Körper gefährlich ist“, schreibt Sara Ahmed und weist darauf hin, wie auch unsere Ängste sozial ko-konstruiert sind (",
        fourthLink: "hier klicken, um zu dem englischen Blog-Artikel „Making Strangers zu lesen",
        fourthSecond: "). Soziale Ängste verwalten unterschiedliche Körper und halten sie an ihren vermeintlich angemessenen (sozialen) Platz.",
        fifth: "Das rassistische Narrativ der „Fremden Gefahr“ dient somit den vielschichtigen Zielen einer rassistischen Heteropartiarchie: Es beschränkt weiße Frauen auf ihre „privaten Räume“ und definiert damit sicherheitsrelevante Weiblichkeit als verletzliche Opfer, weiß und an den Herd in ihrem Haushalt (d.h. bürgerlich) gebunden. In der Folge reproduziert sie eine Art von Rassismus, der dazu dient, Schwarze, braune, Arbeiter- und arme Körper unter dem Vorwand zu überwachen, sie seien allein durch ihre Existenz eine Gefahr. Das ist Rassismus in seiner reinsten Form. Racial Profiling ist eine der vielen Ausdrucksformen davon. Weiße Frauen, die ihr Weißsein zu einer Waffe machen und die Polizei auf BIPoC hetzen, weil sie deren Existenz als „verdächtig“ oder „bedrohlich“ wahrnehmen, ist eine andere.",
        sixth: "Schließlich soll damit davon abgelenkt werden, dass es eigentlich der vom Patriarchat geschaffene private Raum ist, der für Frauen am gefährlichsten ist, was die statistische Wahrscheinlichkeit betrifft, häusliche, sexuelle oder sexualisierte Gewalt und Missbrauch zu erleiden. Doch die Furcht und Angst vor dem (imaginierten) Fremden, in das wir hineinwachsen, hält uns genau dort, in einer potentiellen Gefahrenzone – während sie uns alle davon abhält, eine Gefahr für die patriarchale Politik zu werden, indem wir den öffentlichen Raum betreten und ihn als den unseren beanspruchen."
    },
    english: {
        date: "February 14, 2021",
        arrow: "BLOG << ",
        headline: " Who are we afraid of? The „Stranger“ as a Racist and Classist Projection",
        firstFirst: "This blog article is a continuation of the ",
        firstLink: "report on the workshop „Documenting Nocturnal Flâneuserie“",
        firstSecond: ". It deepens the discussions on how the feelings of fear of certain bodies are shaped by societal narratives.",
        second: "The black male body is othered as a stranger in a place imagined as white by the white gaze and thus turned into a body that is read as disruptive, as “off”, as potentially dangerous. This horror-phantasy of the dangerous “other”posits white middle-class women as victims worthy of protection by the prototypical white male heroes who fight for “their women”. White women thus become the embodiment of pure whiteness in contrast to the imagined “other”. ",
        third: "It is important to recognize, that this ties back to a violent colonial history in which imperialism was and is justified by what Sara Ferris calls Femonationalism: the appropriation of feminist ideas and the call for safety for women with the aim of justifying nationalism, racism, colonisation and patriarchy. It is “white men saving brown women from brown men“ who are meant to morally justify racist colonialism and imperialism (Spivak in “Can the Subaltern Speak”).",
        fourthFirst:"This history is not without consequences, and its techniques and ideologies still circulate today in order to socially manage our bodies and their appearance in public space „There can be nothing more dangerous to a body than the social agreement that that body is dangerous,“ writes Sara Ahmed, pointing out how our fears are also socially co-constructed (",
        fourthLink: "click here to read the blog article „Making Strangers“",
        fourthSecond: "). Social fears manage different bodies and restrain them to their allegedly appropriate (social) places.",
        fifth: "The racist narrative of “Stranger Danger” thus serves the multi-layered objectives of a racist hetero-patriarchy: it confines white women to their “private spaces” thus defining womanhood worthy of safety as vulnerable victims, white and bound to the herd in her household (i.e. bourgeois). Subsequently, it reproduces a kind of racism that serves to police Black, brown, working class and poor bodies under the pretense of them being a danger simply by their existence. That is racism in its purest form. Racial profiling is one of the many expressions of this. White women weaponizing their whiteness and calling the police on BIPoC as they perceive their existence as “suspicious” or “threatening” is another.",
        sixth: "Finally, this serves to distract from the fact that it is actually the private space that patriarchy created which is most dangerous for women regarding the statistical probability of suffering domestic, sexual or sexualized violence and abuse. Yet the fear and anxiety of the (imagined) stranger we grow into keeps us right there, in a potential danger zone – while keeping all of us away from becoming a danger to patriarchal politics by entering the public space and claiming it as ours."
    }
}
export {WhoAreWeAfraidOfContent}